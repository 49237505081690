import { Link } from "react-router-dom"

export default function Burger({scrollToLeaderboard, setIsOpen, isOpen}){
    return (
        <div className={`z-50 fixed w-screen h-screen bg-grey-darker flex md:hidden flex-col justify-between left-0 bottom-0 ${isOpen ? "translate-x-0" : "-translate-x-full"} duration-300`}>
        <div className="flex items-center h-[119px] px-6 justify-between">
          <Link to="/">
            <img alt="logo" src="/logo.png" />
          </Link>
          <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer" onClick={() => setIsOpen(false)} width="40" height="40" viewBox="0 0 24 24"><path className="fill-yellow" d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" /></svg>
        </div>
        <ul className="flex flex-col gap-10">
          <li>
            <Link to="#leaderboard" onClick={() => { scrollToLeaderboard() }} className="font-poppins text-xl text-white uppercase cursor-pointer font-medium">Leaderboard</Link>
          </li>
          <li>
            <a href="https://www.youtube.com/@biscottefamily4350" className="font-poppins text-xl text-white uppercase font-medium">Biscotte family</a>
          </li>
        </ul>
        <div className="h-[119px] w-full flex items-center justify-center">
          <p className="text-white uppercase">Biscotte family © {new Date().getFullYear()}</p>
        </div>
      </div>
    )
}