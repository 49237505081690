import youtube from "../../assets/youtube.png";
import twitch from "../../assets/twitch.png";

export default function Footer() {
  return (
    <footer className="w-full flex justify-center h-[60px] bg-grey px-6 lg:px-12 xl:px-[100px]">
      <div className="gap-3 md:gap-0 mt-auto h-full w-full shrink-0 text-xs md:text-base  flex items-center justify-between max-w-[1528px]">
        <p className={"flex-1"}>
          Made <br className="md:hidden" /> by{" "}
          <a href="https://jeremiebarriere.fr" target={"_blank"} rel={"noreferrer"} className="underline">
            Jérémie Barrière
          </a>
        </p>
        <p className={"flex-1 text-center"}>Masteries Biscotte Family © {new Date().getFullYear()}</p>
        <div className="flex flex-1 items-center justify-end gap-3 md:gap-10 ">
          <div className="h-full w-[20px]">
            <a
              className=" cursor-pointer"
              target="_blank"
              rel="noreferrer"
              href="https://www.twitch.tv/biscottefamily"
            >
              <img alt="twitch" className="" src={twitch} />
            </a>
          </div>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/channel/UCAOf6CQwgJCH4GJsulmWg8g"
          >
            <img
              alt="youtube"
              className="w-[25px] h-full fill-current"
              src={youtube}
            />
          </a>
        </div>
      </div>
    </footer>
  );
}
