import axios from "axios";

export async function getSummonerInfos(summonerName) {
    try{
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/summoner`,{summoner:summonerName});
        return response.data;
    }catch(e){
        return null
    }
    
}

export async function getLeaderboard(){
    try{
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/leaderboard`);
        return response.data;
    }catch(e){
        return null
    }
}

