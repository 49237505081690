import "@babel/polyfill";
import * as React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

const matomoInstance = createInstance({
  urlBase: 'https://analytics.biscottefamily.fr/', // URL de votre instance Matomo
  siteId: 1, // ID de votre site dans Matomo
});

const root = ReactDOM.createRoot(
  document.getElementById("root")
);
root.render(
  <React.StrictMode>
    <MatomoProvider value={matomoInstance}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MatomoProvider>
  </React.StrictMode>
);