import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { getLeaderboard } from "../../../api/summoner"

export default function Leaderboard() {

    const [showTotalList, setShowTotalList] = useState(false)
    const [showBestList, setShowBestList] = useState(false)
    const [totalState, setTotalState] = useState([false, false, false])
    const [bestState, setBestState] = useState([false, false, false])
    const [totalList, setTotalList] = useState([])
    const [bestList, setBestList] = useState([])

    const handleTotalState = (index) => {
        const newTotalState = [...totalState]
        newTotalState[index] = !newTotalState[index]
        setTotalState(newTotalState)
    }

    const handleBestState = (index) => {
        const newBestState = [...bestState]
        newBestState[index] = !newBestState[index]
        setBestState(newBestState)
    }

    const handleTotalList = () => {
        const array = data
        const sortedData = array.sort((a, b) => b.totalMasteries - a.totalMasteries);
        setTotalList(sortedData);
    }

    const handleBestList = () => {
        const sorting = data.map((item) => ({
            id: item.id,
            best: item.bestMasteries,
        }));
        const sorted = sorting.sort((a, b) => b.best - a.best);
        const sortedData = sorted.reduce((acc, currentItem) => {
            const foundItem = data.find((item) => item.id === currentItem.id);
            if (foundItem) {
                acc.push(foundItem);
            }
            return acc;
        }, []);
        setBestList(sortedData);
    };

    const handleShowBestList = () => {
        if (data?.length > 3) {
            let length = (data?.length - 3) * 39
            return `${length}px`
        } else {
            return "0"
        }
    }

    const handleShowTotalList = () => {
        if (data?.length > 3) {
            let length = (data?.length - 3) * 39
            return `${length}px`
        } else {
            return "0"
        }
    }


    const [data, setData] = useState([]);

    const getTheLeaderboard = async () => {
        const response = await getLeaderboard();
        if(response){
        setData(response);
        }
    };

    useEffect(() => {
        getTheLeaderboard();
    }, []);

    useEffect(() => {
        handleShowBestList()
        handleShowTotalList()
        handleTotalList()
        handleBestList()
    }, [data]);

    useEffect(() => {
        console.log(totalList)
    }, [totalList])

    return (
        <section id="leaderboard" className="h-full w-full flex flex-col items-center min-h-screen mb-[100px]">
            <h2 className="font-boogaloo text-yellow uppercase text-[2rem] md:text-[3rem] xl:text-[4rem] min-w-max text-center tracking-[0.04em] mt-[120px]">Leaderboard</h2>
            <div className="flex flex-col xl:w-auto w-full sm:w-4/5 md:w-2/3 justify-center mt-20 items-center">
                <div className="flex items-end w-full">
                    <div className="w-full text-center">
                        <Link to={`/search?summoner=${totalList && totalList[1]?.summonerName}`} className="font-boogaloo text-white md:text-2xl mb-[15px]">{totalList && totalList[1]?.summonerName}</Link>
                        <div onClick={() => { handleTotalState(0) }} className="h-20 md:h-[121px] w-full xl:w-[228px] flex items-center relative group justify-center bg-grey-second">
                            <p className={`text-white font-boogaloo text-2xl md:text-[40px] opacity-100  ${totalState[0] ? "!opacity-0" : "group-hover:opacity-0"} duration-300`}>2nd</p>
                            <p className={`text-white font-medium md:text-2xl absolute opacity-0 ${totalState[0] ? "!opacity-100 " : " group-hover:opacity-100"} duration-300`}>{totalList && totalList[1]?.totalMasteriesFormated}</p>
                        </div>
                    </div>
                    <div className="w-full text-center">
                        <Link to={`/search?summoner=${totalList && totalList[0]?.summonerName}`} className="font-boogaloo text-white md:text-2xl mb-[15px]">{totalList && totalList[0]?.summonerName}</Link>
                        <div onClick={() => { handleTotalState(1) }} className="h-[133px] md:h-[201px] w-full xl:w-[228px] flex items-center relative group justify-center bg-yellow">
                            <div className={`opacity-100 ${totalState[1] ? "!opacity-0" : "group-hover:opacity-0"} duration-300`}>
                                <img src="/trophyMan.png" className="w-16 md:w-24" alt="trophy" />
                                <p className={`text-white font-boogaloo text-2xl md:text-[40px] `}>1st</p>
                            </div>
                            <p className={`text-white font-medium md:text-2xl absolute opacity-0 ${totalState[1] ? "!opacity-100 " : " group-hover:opacity-100"} duration-300`}>{totalList && totalList[0]?.totalMasteriesFormated}</p>
                        </div>
                    </div>
                    <div className="w-full text-center">
                        <Link to={`/search?summoner=${totalList && totalList[2]?.summonerName}`} className="font-boogaloo text-white md:text-2xl mb-[15px]">{totalList && totalList[2]?.summonerName}</Link>
                        <div onClick={() => { handleTotalState(2) }} className=" h-[54px] md:h-20 w-full xl:w-[228px] flex items-center relative group justify-center bg-yellow-third">
                            <p className={`text-white font-boogaloo text-2xl md:text-[40px] opacity-100 ${totalState[2] ? "!opacity-0" : "group-hover:opacity-0"} duration-300`}>3rd</p>
                            <p className={`text-white font-medium md:text-2xl absolute opacity-0 ${totalState[2] ? "!opacity-100 " : " group-hover:opacity-100"} duration-300`}>{totalList && totalList[2]?.totalMasteriesFormated}</p>
                        </div>
                    </div>
                </div>
                <div className="h-10 md:h-[49px] bg-white w-full flex items-center justify-between px-5 border-y border-y-black">
                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setShowTotalList(!showTotalList)} className={`${showTotalList && "-rotate-180"} cursor-pointer duration-300 w-6 h-6 md:w-8 md:h-8`} viewBox="0 0 24 24"><path fill="none" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m6 9l6 6l6-6" /></svg>
                    <h3 className="font-boogaloo md:text-2xl">Classement du total des maitrises</h3>
                    <div className="relative group">
                        <svg xmlns="http://www.w3.org/2000/svg" className="opacity-30 cursor-pointer md:visible invisible" width="20" height="20" viewBox="0 0 16 16"><path fill="#303030 " d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8Zm8-6.5a6.5 6.5 0 1 0 0 13a6.5 6.5 0 0 0 0-13ZM6.92 6.085h.001a.749.749 0 1 1-1.342-.67c.169-.339.436-.701.849-.977C6.845 4.16 7.369 4 8 4a2.756 2.756 0 0 1 1.637.525c.503.377.863.965.863 1.725c0 .448-.115.83-.329 1.15c-.205.307-.47.513-.692.662c-.109.072-.22.138-.313.195l-.006.004a6.24 6.24 0 0 0-.26.16a.952.952 0 0 0-.276.245a.75.75 0 0 1-1.248-.832c.184-.264.42-.489.692-.661c.103-.067.207-.132.313-.195l.007-.004c.1-.061.182-.11.258-.161a.969.969 0 0 0 .277-.245C8.96 6.514 9 6.427 9 6.25a.612.612 0 0 0-.262-.525A1.27 1.27 0 0 0 8 5.5c-.369 0-.595.09-.74.187a1.01 1.01 0 0 0-.34.398ZM9 11a1 1 0 1 1-2 0a1 1 0 0 1 2 0Z" /></svg>
                        <div className="w-[200px] hidden xl:block rounded-md bg-white opacity-0 pointer-events-none top-0 group-hover:opacity-100 py-3 px-2 duration-200 ease-in  absolute -right-full translate-x-[105%] ">
                            <p>Ce classement prend en compte la somme des maitrises de tous les champions des invocateurs.</p>
                        </div>
                    </div>                </div>
                <div className={`w-full duration-300 bg-white flex flex-col overflow-hidden`}
                    style={{ height: showTotalList ? handleShowTotalList() : "0" }}
                >
                    {totalList?.map((player, index) => (
                        index > 2 && <div key={index} className="flex odd:bg-grey items-center h-[39px] justify-between px-2.5 md:px-5">
                            <div className="flex items-center gap-1.5 w-1/3">
                                <p className="md:text-base text-xs  font-boogaloo">{(index + 1) + "."}</p>
                                <p className="md:text-base text-xs  ">{player.summonerName}</p>
                            </div>
                            <p className="md:text-base text-xs text-center w-1/3">{player.totalMasteriesFormated + " pts"}</p>
                            <a href={`/search?summoner=${player.summonerName}`} className="md:text-base text-xs  w-1/3 text-right underline">Voir plus</a>
                        </div>))}
                </div>
            </div>
            <div className="flex flex-col xl:w-auto w-full sm:w-4/5 md:w-2/3 justify-center mt-20 items-center">
                <div className="flex items-end w-full">
                    <div className="w-full text-center">
                        <Link to={`/search?summoner=${bestList && bestList[1]?.summonerName}`} className="font-boogaloo text-white md:text-2xl mb-[15px]">{bestList && bestList[1]?.summonerName}</Link>
                        <div onClick={() => { handleBestState(1) }} className="h-20 md:h-[121px] w-full xl:w-[228px] flex items-center relative group justify-center bg-grey-second">
                            <p className={`text-white font-boogaloo text-2xl md:text-[40px] opacity-100  ${bestState[1] ? "!opacity-0" : "group-hover:opacity-0"} duration-300`}>2nd</p>
                            <div className={` absolute opacity-0 ${bestState[1] ? "!opacity-100 " : " group-hover:opacity-100"} duration-300 flex items-center gap-[15px]`}>
                                <img className="w-8 md:w-10" alt={bestList && bestList[1]?.championName} src={bestList && bestList[1]?.championSplash} />
                                <p className={`text-white font-medium md:text-2xl`}>{bestList && bestList[1]?.bestMasteriesFormated}</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full text-center">
                        <Link to={`/search?summoner=${bestList && bestList[0]?.summonerName}`} className="font-boogaloo text-white md:text-2xl mb-[15px]">{bestList && bestList[0]?.summonerName}</Link>
                        <div onClick={() => { handleBestState(0) }} className="h-[133px] md:h-[201px] w-full xl:w-[228px] flex items-center relative group justify-center bg-yellow">
                            <div className={`opacity-100 ${bestState[0] ? "!opacity-0" : "group-hover:opacity-0"} duration-300`}>
                                <img src="/trophy.png" className="w-12 md:w-20" alt="trophy" />
                                <p className={`text-white font-boogaloo text-2xl md:text-[40px] `}>1st</p>
                            </div>
                            <div className={`absolute opacity-0 ${bestState[0] ? "!opacity-100 " : " group-hover:opacity-100"} duration-300 flex flex-col items-center gap-[15px]`}>
                                <img className="w-20 md:w-28 aspect-square" alt={bestList && bestList[0]?.championName} src={`/champions/3d/${bestList && bestList[0]?.championName?.toLowerCase()}.png`} />
                                <p className={`text-white font-medium md:text-2xl duration-300`}>{bestList && bestList[0]?.bestMasteriesFormated}</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full text-center">
                        <Link to={`/search?summoner=${bestList && bestList[2]?.summonerName}`} className="font-boogaloo text-white md:text-2xl mb-[15px]">{bestList && bestList[2]?.summonerName}</Link>
                        <div onClick={() => { handleBestState(2) }} className="h-[54px] md:h-20 w-full xl:w-[228px] flex items-center relative group justify-center bg-yellow-third">
                            <p className={`text-white font-boogaloo text-2xl md:text-[40px] opacity-100 ${bestState[2] ? "!opacity-0" : "group-hover:opacity-0"} duration-300`}>3rd</p>
                            <div className={`absolute opacity-0 ${bestState[2] ? "!opacity-100 " : " group-hover:opacity-100"} duration-300 flex items-center gap-[15px]`}>
                                <img className="w-8 md:w-10 aspect-square" alt={bestList && bestList[2]?.championName} src={bestList && bestList[2]?.championIcon} />
                                <p className={`text-white font-medium md:text-2xl `}>{bestList && bestList[2]?.bestMasteriesFormated}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" h-10 md:h-[49px] bg-white w-full flex items-center justify-between px-5 border-y border-y-black">
                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setShowBestList(!showBestList)} className={`${showBestList && "-rotate-180"} cursor-pointer duration-300 w-6 h-6 md:w-8 md:h-8`} viewBox="0 0 24 24"><path fill="none" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m6 9l6 6l6-6" /></svg>
                    <h3 className="font-boogaloo md:text-2xl">Classement par meilleure maitrise</h3>
                    <div className="relative group">
                        <svg xmlns="http://www.w3.org/2000/svg" className="opacity-30 cursor-pointer md:visible invisible" width="20" height="20" viewBox="0 0 16 16"><path fill="#303030" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8Zm8-6.5a6.5 6.5 0 1 0 0 13a6.5 6.5 0 0 0 0-13ZM6.92 6.085h.001a.749.749 0 1 1-1.342-.67c.169-.339.436-.701.849-.977C6.845 4.16 7.369 4 8 4a2.756 2.756 0 0 1 1.637.525c.503.377.863.965.863 1.725c0 .448-.115.83-.329 1.15c-.205.307-.47.513-.692.662c-.109.072-.22.138-.313.195l-.006.004a6.24 6.24 0 0 0-.26.16a.952.952 0 0 0-.276.245a.75.75 0 0 1-1.248-.832c.184-.264.42-.489.692-.661c.103-.067.207-.132.313-.195l.007-.004c.1-.061.182-.11.258-.161a.969.969 0 0 0 .277-.245C8.96 6.514 9 6.427 9 6.25a.612.612 0 0 0-.262-.525A1.27 1.27 0 0 0 8 5.5c-.369 0-.595.09-.74.187a1.01 1.01 0 0 0-.34.398ZM9 11a1 1 0 1 1-2 0a1 1 0 0 1 2 0Z" /></svg>
                        <div className="w-[200px] hidden xl:block rounded-md bg-white opacity-0 pointer-events-none -translate-y-full group-hover:opacity-100 py-3 px-2 duration-200 ease-in  absolute -right-full translate-x-[105%] ">
                            <p>Ce classement prend uniquement en compte le champion ayant le plus grand nombre de points de maitrise pour chaque invocateur.</p>

                        </div>
                    </div>
                </div>
                <div className={`w-full duration-300 bg-white flex flex-col overflow-hidden`}
                    style={{ height: showBestList ? handleShowBestList() : "0" }}
                >
                    {bestList?.map((player, index) => (
                        index > 2 && <div key={index} className="grid grid-cols-4 gap-5 md:gap-10 w-full odd:bg-grey items-center h-[39px] justify-between px-2.5 md:px-5">
                            <div className="flex items-center gap-1.5 ">
                                <p className=" lg:text-base text-xs font-boogaloo">{(index + 1) + "."}</p>
                                <p className=" lg:text-base text-xs ">{player.summonerName}</p>
                            </div>
                            <p className=" lg:text-base text-xs  text-center">{player.championName}</p>
                            <p className=" lg:text-base text-xs  text-center">{player.bestMasteriesFormated + " pts"}</p>
                            <a href={`/search?summoner=${player.summonerName}`} className=" lg:text-base text-xs  text-right underline">Voir plus</a>
                        </div>))}
                </div>
            </div>
        </section>
    )
}