import { useEffect } from "react";

export default function Hero() {

  const scrollToResearch = () => {
    const section = document.getElementById("search");
    section.scrollIntoView({ behavior: "smooth" });
  }


  return (
    <section className="h-full w-full flex justify-center items-center pb-20 md:pb-[139px]" style={{ minHeight: "calc(100vh - 119px)" }}>
      <div className="md:grid grid-cols-12 gap-8 relative hidden max-w-[1528px] ">
        <p className="hidden-title pointer-events-none z-10">Masteries</p>
        <div className="lg:col-start-2 col-span-6 lg:col-span-4 flex flex-col justify-center items-start z-10">
          <h1 className="hero-title hero-title-first text-white z-10"><span>Masteries</span></h1>
          <h1 className="hero-title hero-title-second text-yellow z-10 "><span>Biscotte Family</span></h1>
          <p id="hero-description" className="text-white mt-10 mb-20 text-left z-10 ">Le site qui te permet de tout savoir sur tes maitrises de ton compte League of Legends grâce à ton nom d’invocateur</p>
          <button id="hero-button" className="bg-yellow rounded-md text-xl font-medium min-w-max py-5 px-[35px] text-[#303030] z-10 hover:bg-[#F4D385] duration-200 hover:shadow-light" onClick={scrollToResearch}>Chercher un invocateur</button>
        </div>
        <div className="col-span-5 lg:col-span-4 col-start-8 lg:col-start-8 z-10">
          <img src="/biscotte.png" id="hero-image" alt="biscotte-family" />
        </div>
      </div>
      <div className="w-full flex flex-col md:hidden items-center ">
        <div className="mb-[30px]">
          <h1 className="hero-title hero-title-first text-white z-10"><span>Masteries</span></h1>
          <h1 className="hero-title hero-title-second text-yellow z-10 "><span>Biscotte Family</span></h1>
        </div>
          <img src="/biscotte.png" id="hero-image" className="w-[74%]" alt="biscotte-family" />
          <div className="w-full mt-[45px]">
          <p id="hero-description" className="text-white text-left z-10 leading-[180%] ">Le site qui te permet de tout savoir sur tes maitrises de ton compte League of Legends grâce à ton nom d’invocateur</p>
          </div>
          <button id="hero-button" className="bg-yellow mt-[30px] rounded-md py-[15px] font-medium min-w-max w-full text-[#303030] z-10 hover:bg-[#F4D385] duration-200 hover:shadow-light" onClick={scrollToResearch}>Chercher un invocateur</button>
      </div>
    </section>
  )
}