export default function Switch({switchMode, setSwitchMode}){

    const switchTheMode = (value) => {
        localStorage.setItem("masteries-switchMode", value)
        setSwitchMode(value)
    }

    return (
        <div className="w-[300px] flex h-[62px] bg-grey border-[3px] border-yellow rounded-xl relative">
                        <span className={`duration-200 w-1/2 bg-yellow h-full absolute z-[5] ${switchMode ? "translate-x-full rounded-r-[5px]" : "rounded-l-[5px]"}`}></span>
                        <button onClick={() => switchTheMode(false)} className={`${switchMode ? "text-black/[0.5]" : "text-grey-darker"} uppercase duration-200 h-full w-full font-boogaloo text-xl bg-transparent z-10`}>
                            One by one
                        </button>
                        <button onClick={() => switchTheMode(true)} className={`${switchMode ? "text-grey-darker" : "text-black/[0.5]"} uppercase duration-200 h-full w-full font-boogaloo text-xl bg-transparent z-10`}>
                            Liste
                        </button>
                    </div>
    )
}