import {XCircleIcon} from '@heroicons/react/20/solid'

export function Banner() {
    return (
        <div className="rounded-md bg-red-50 p-4 w-full">
            <div className="flex">
                <div className="flex-shrink-0">
                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-red-800">Il est actuellement impossible de récupérer les données depuis l'API League of Legends car le endpoint est pour le moment hors-service</p>
                </div>
            </div>
        </div>
    )
}
