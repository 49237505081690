import { useMatomo } from "@datapunt/matomo-tracker-react";
import * as React from "react";
import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import Search from "./pages/Search/Search";

export default function App() {
  
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search" element={<Search />} />
      </Routes>
    </div>
  );
}