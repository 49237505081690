import { useEffect, useState } from "react"

export default function Search() {

  const [researched, setResearched] = useState([])

  const handleSubmit = (e) => {
    e.preventDefault()
    const newResearch = researched
    newResearch.unshift(e.target.summoner.value)
    console.log(newResearch)
    if (newResearch.length > 10) {
      newResearch.pop()
    }
    localStorage.setItem('researched', JSON.stringify(newResearch))
    setResearched(newResearch)
    window.location.href = `/search?summoner=${e.target.summoner.value}`
  }

  useEffect(() => {
    if (localStorage.getItem('researched')) {
      setResearched(JSON.parse(localStorage.getItem('researched')))
    } else {
      localStorage.setItem('researched', JSON.stringify([]))
    }
  }, [])

  const handleDelete = (index) => {
    const newResearch = [...researched]
    newResearch.splice(index, 1)
    localStorage.setItem('researched', JSON.stringify(newResearch))
    setResearched(newResearch)
  }



  return (
    <section className="h-full w-full flex flex-col my-[120px] justify-center items-center">
      <h2 className="font-boogaloo text-grey-darker/[0.80] uppercase text-[2rem] md:text-[3rem] xl:text-[4rem] min-w-max text-center tracking-[0.04em]">Chercher un invocateur</h2>
      <form onSubmit={handleSubmit} className="lg:grid grid-cols-12 flex flex-col gap-4  max-w-[1528px] lg:gap-8 w-full mt-[50px]">
        <input placeholder="Nom d'invocateur" name="summoner" className=" h-16 md:h-20 col-span-7 bg-[#F4F4F4] pl-10 font-medium md:text-2xl border-2 border-[#E4E4E4] rounded-md outline-yellow col-start-2" />
        <button type="submit" className="bg-yellow col-span-3 md:py-3.5 py-1.5 lg:py-[25px] rounded-md font-medium text-xl hover:bg-[#F4D385] duration-200 hover:shadow-light">Rechercher</button>
      </form>
      <h3 className="font-boogaloo text-grey-darker mt-[35px] mb-[50px] text-2xl md:text-[30px] lg:text-[40px] min-w-max text-center">{researched?.length > 0 ? "Vos dernières recherches" : "Aucune recherche, faites-en une !"}</h3>
      <ol className="grid grid-cols-2 gap-x-[50px] gap-y-7 list-decimal">
        {researched?.map((item, index) => (
          <li key={index} className="w-full">
            <div className="w-full flex items-center gap-2">
              <a href={`/search?summoner=${item}`} className="underline">
                {item}
              </a>
              <button onClick={() => handleDelete(index)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path className="fill-grey-darker" d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"/></svg></button>
            </div>
          </li>))}
      </ol>
    </section>
  )
} 