import * as React from "react";
import Footer from "../../components/molecule/Footer";
import Header from "./components/Header";
import Search from "./components/Search";
import Hero from "./components/Hero";
import Leaderboard from "./components/Leaderboard";
import { useEffect } from "react";
import { useState } from "react";
import Burger from "./components/Burger";
import {Banner} from "../../components/atoms/Banner";



export default function Home() {

  const [isOpen, setIsOpen] = useState(false);

  const scrollToLeaderboard = () => {
    setIsOpen(false);
    const leaderboard = document.getElementById('leaderboard');
    leaderboard?.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    if (window.location.hash === "#leaderboard") {
      const leaderboard = document.getElementById('leaderboard');
      leaderboard?.scrollIntoView({ behavior: 'smooth' });
    }  
  }, []);

  return (
    <section className="w-full flex flex-col items-center min-h-screen">
      <Banner/>
      <div className="flex flex-col items-center min-h-screen justify-between w-full " >
        <div className="w-full flex justify-center px-6 lg:px-12 xl:px-[100px] bg-grey-darker">
          <Header setIsOpen={setIsOpen} scrollToLeaderboard={scrollToLeaderboard}/>
        </div>
        <div className="w-full relative overflow-hidden  px-6 lg:px-12 xl:px-[100px] bg-grey-darker">
          <Hero />
        </div>
        <div id="search" className="w-full min-h-screen flex flex-col justify-center relative  px-6 lg:px-12 xl:px-[100px] bg-white">
          <span className="absolute top-0 z-10 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-yellow  w-5 h-[50px]"></span>
          <span className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 rounded-full bg-yellow  w-5 h-[50px]"></span>
          <Search />
        </div>
        <div className="w-full  px-6 lg:px-12 xl:px-[100px] bg-grey-darker">
          <Leaderboard />
        </div>
        <Footer />
      </div>
      <Burger scrollToLeaderboard={scrollToLeaderboard} isOpen={isOpen} setIsOpen={setIsOpen} />
    </section>

  );
}
