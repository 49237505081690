import { useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { getSummonerInfos } from "../../api/summoner";
import "../../summoner.css";
import Header from "../../components/molecule/Header";
import React from "react";
import UseAnimations from "react-useanimations";
import loading from "react-useanimations/lib/loading";
import Template from "../../components/molecule/Template";
import Switch from "./components/Filtres/Switch";
import FilterItem from "./components/Filtres/FilterItem";
import FilterButton from "./components/Filtres/FilterButton";
import LevelBadge from "./components/List/LevelBadge";
import VisibilitySensor from "react-visibility-sensor";

export default function Search() {
  const [data, setData] = useState(null);
  const [levelTotal, setLevelTotal] = useState(0);
  const [chestTotal, setChestTotal] = useState(0);
  const [levelMax, setLevelMax] = useState(0);
  const [leLoading, setLeLoading] = useState(true);
  const [championsByLevel, setChampionsByLevel] = useState([]);
  const [selectedChampion, setSelectedChampion] = useState(null);
  const [filtresOpen, setFiltresOpen] = useState(false);
  const [switchMode, setSwitchMode] = useState(false);
  const [displayLength, setDisplayLength] = useState(null);
  const [hide7, setHide7] = useState(false);
  const [hide6, setHide6] = useState(false);
  const [hide5, setHide5] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const listRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  let [searchParams, setSearchParams] = useSearchParams();
  const summonerName = searchParams.get("summoner");

  const getInfos = async () => {
    setLeLoading(true);
    const response = await getSummonerInfos(summonerName);
    setData(response);
    setLeLoading(false);
  };

  const handleTitleColor = (level) => {
    switch (level) {
      case "7":
        return "text-masteries-7";
      case "6":
        return "text-masteries-6";
      case "5":
        return "text-masteries-5";
      case 7:
        return "text-masteries-7";
      case 6:
        return "text-masteries-6";
      case 5:
        return "text-masteries-5";
      default:
        return "text-yellow";
    }
  };

  const handleLevelColor = (level) => {
    switch (level) {
      case 7:
        return "bg-masteries-7";
      case 6:
        return "bg-masteries-6";
      case 5:
        return "bg-masteries-5";
      default:
        return "bg-[#EDB54A]";
    }
  };

  const handleBgLevelColor = (level) => {
    switch (level) {
      case "7":
        return "bg-masteries-7/[0.2]";
      case "6":
        return "bg-masteries-6/[0.2]";
      case "5":
        return "bg-masteries-5/[0.2]";
      case 7:
        return "bg-masteries-7/[0.2]";
      case 6:
        return "bg-masteries-6/[0.2]";
      case 5:
        return "bg-masteries-5/[0.2]";
      default:
        return "bg-[#EDB54A]/[0.2]";
    }
  };

  const handleBoxShadow = (level) => {
    switch (level) {
      case 7:
        return "shadow-[0_1px_30px_5px_rgba(36,182,215,0.2)]";
      case 6:
        return "shadow-[0_1px_30px_5px_rgba(191,32,108,0.2)]";
      case 5:
        return "shadow-[0_1px_30px_5px_rgba(208,30,30,0.2)]";
      default:
        return "shadow-[0_1px_30px_5px_rgba(0,0,0,0.1)]";
    }
  };

  function tokenDisplay(level, tokensEarned) {
    const tokenMax = level === 5 ? 2 : level === 6 ? 3 : 0;
    const tokensDisplayed = Array.from({ length: tokenMax }, (_, i) => {
      if (i < tokensEarned) {
        return (
          <img
            key={i}
            className={`w-3.5 md:w-7`}
            alt="coffre-acquis"
            src="https://championmastery.gg/img/token.png"
          />
        );
      } else {
        return (
          <img
            key={i}
            className={`w-3.5 md:w-7 notEarned`}
            alt="coffre-acquis"
            src="https://championmastery.gg/img/token.png"
          />
        );
      }
    });
    return <>{tokensDisplayed}</>;
  }

  const handleProgress = (champion) => {
    switch (champion?.championLevel) {
      case 7:
        return (
          <div className="relative col-span-2 w-full flex items-center h-[25px] border rounded-full border-[#D3F0F7]">
            <p className="w-full text-center text-xs md:text-sm font-medium z-[5]">
              Maitrisé
            </p>
            <div
              className={`absolute rounded-full bg-[#D3F0F7] w-full h-full`}
            />
          </div>
        );
      case 6:
        return (
          <div className="flex items-center justify-center col-span-2 gap-2 md:gap-5">
            {tokenDisplay(champion?.championLevel, champion?.tokensEarned)}
          </div>
        );
      case 5:
        return (
          <div className="flex items-center justify-center col-span-2 gap-2 md:gap-5">
            {tokenDisplay(champion?.championLevel, champion?.tokensEarned)}
          </div>
        );
      default:
        return (
          <div className="relative col-span-2 w-full flex items-center h-[25px] border overflow-hidden rounded-full border-yellow">
            <p className="w-full text-center text-xs md:text-sm font-medium z-[5]">
              {champion?.championPointsUntilNextLevel}
            </p>
            <div
              style={{ width: champion?.tokensEarned + "%" }}
              className={`absolute rounded-l-full bg-yellow h-full`}
            />
          </div>
        );
    }
  };

  function championList() {
    const championsByLevel = {};
    data &&
      data[1].forEach((champion) => {
        if (!championsByLevel[champion.championLevel]) {
          championsByLevel[champion.championLevel] = [];
        }
        championsByLevel[champion.championLevel].push(champion);
      });
    setChampionsByLevel(championsByLevel);
  }

  function handleProgressSort(value) {
    const table = data[1];
    let sortedData = table;
    if (value === false) {
      sortedData = table.sort((a, b) => {
        if (a?.championLevel === 7 && b?.championLevel === 7) {
          return b?.tokensEarned - a?.tokensEarned; // sort? championLevel 7 by tokensEarned descending
        }
        if (a?.championLevel === 7) {
          return -1; //? championLevel 7 should come before all other? championLevels
        }
        if (b?.championLevel === 7) {
          return 1; // all other? championLevels should come after? championLevel 7
        }
        if (
          a.championLevel >= 5 &&
          b.championLevel >= 5 &&
          a.championLevel === b.championLevel
        ) {
          return b.tokensEarned - a.tokensEarned;
        }
        if (a.championLevel < 5 && b.championLevel < 5) {
          return b.tokensEarned - a.tokensEarned;
        }
        return b?.championLevel - a?.championLevel; // sort by level descending
      });
    } else {
      sortedData = table.sort((a, b) => {
        if (a.championLevel === 7 && b.championLevel === 7) {
          return a.tokensEarned - b.tokensEarned; // switch the order of a and b here
        }
        if (a.championLevel === 7) {
          return 1; // switch the order of the return values here
        }
        if (b.championLevel === 7) {
          return -1; // switch the order of the return values here
        }
        if (
          a.championLevel >= 5 &&
          b.championLevel >= 5 &&
          a.championLevel === b.championLevel
        ) {
          return a.tokensEarned - b.tokensEarned; // switch the order of a and b here
        }
        if (a.championLevel < 5 && b.championLevel < 5) {
          return a.tokensEarned - b.tokensEarned; // switch the order of a and b here
        }
        return a.championLevel - b.championLevel; // switch the order of a and b here
      });
    }
    const firstTable = data[0];
    setData([firstTable, sortedData]);
  }

  function handleDateSort(value) {
    const table = data[1];
    if (value === true) {
      table.forEach((champion) => {
        champion.lastPlayTime = champion.lastPlayTime.replace(/[/]/g, "-");
        champion.lastPlayTime = champion.lastPlayTime
          .split("-")
          .reverse()
          .join("-");
      });
      const sorted = table.sort((a, b) =>
        Date.parse(a.lastPlayTime) < Date.parse(b.lastPlayTime)
          ? 0
          : a.lastPlayTime
            ? -1
            : 1
      );
      sorted.forEach((champion) => {
        champion.lastPlayTime = champion.lastPlayTime
          .split("-")
          .reverse()
          .join("-");
      });
      const firstTable = data[0];
      setData([firstTable, sorted]);
    } else {
      table.forEach((champion) => {
        champion.lastPlayTime = champion.lastPlayTime.replace(/[/]/g, "-");
        champion.lastPlayTime = champion.lastPlayTime
          .split("-")
          .reverse()
          .join("-");
      });
      const sorted = table.sort((a, b) =>
        Date.parse(b.lastPlayTime) < Date.parse(a.lastPlayTime)
          ? 0
          : b.lastPlayTime
            ? -1
            : 1
      );
      sorted.forEach((champion) => {
        champion.lastPlayTime = champion.lastPlayTime
          .split("-")
          .reverse()
          .join("-");
      });
      const firstTable = data[0];
      setData([firstTable, sorted]);
    }
  }

  const filterList = (value) => {
    switch (value) {
      case "pointsAsc":
        setFilterName("Filtrage points de maitrise décroissant");
        const sortedAsc = data[1].sort(
          (a, b) => a.championPoints - b.championPoints
        );
        const firstTableAsc = data[0];
        setData([firstTableAsc, sortedAsc]);
        break;
      case "pointsDesc":
        setFilterName("Filtrage points de maitrise croissant");
        const sortedDesc = data[1].sort(
          (a, b) => b.championPoints - a.championPoints
        );
        const firstTableDesc = data[0];
        setData([firstTableDesc, sortedDesc]);
        break;
      case "chestYes":
        setFilterName("Filtrage coffre obtenu");
        const sortedChestYes = data[1].sort((a, b) =>
          a.chestGranted === b.chestGranted ? 0 : a.chestGranted ? -1 : 1
        );
        const firstTableChestYes = data[0];
        setData([firstTableChestYes, sortedChestYes]);
        break;
      case "chestNo":
        setFilterName("Filtrage coffre pas obtenu");
        const sortedChestNo = data[1].sort((a, b) =>
          b.chestGranted === a.chestGranted ? 0 : b.chestGranted ? -1 : 1
        );
        const firstTableChestNo = data[0];
        setData([firstTableChestNo, sortedChestNo]);
        break;
      case "dateAsc":
        setFilterName("Filtrage partie la plus récente");
        handleDateSort(true);
        break;
      case "dateDesc":
        handleDateSort(false);
        setFilterName("Filtrage partie la plus ancienne");
        break;
      case "progressAsc":
        setFilterName("Filtrage progression croissante");
        handleProgressSort(true);
        break;
      case "progressDesc":
        setFilterName("Filtrage progression décroissante");
        handleProgressSort(false);
        break;
      default:
        const sortedDefault = data[1].sort(
          (a, b) => b.championPoints - a.championPoints
        );
        const firstTableDefault = data[0];
        setData([firstTableDefault, sortedDefault]);
    }
  };

  useEffect(() => {
    data && getTotaux();
    championList();
  }, [data]);

  useEffect(() => {
    if (listRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("!opacity-100");
            entry.target.classList.add("!translate-x-0");
          }
        });
      });

      const childNodes = listRef?.current.childNodes;
      const childrenArray = Array.from(childNodes);

      childrenArray.forEach((child) => {
        observer.observe(child);
      });

      return () => {
        childrenArray.forEach((child) => {
          observer.unobserve(child);
        });
      };
    }
  }, [data, switchMode]);

  useEffect(() => {
    getInfos();
    if (localStorage.getItem("masteries-switchMode") === null) {
      localStorage.setItem("masteries-switchMode", false);
    } else {
      setSwitchMode(localStorage.getItem("masteries-switchMode") === "true");
    }
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      shouldDisplay(window.innerWidth);

    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const shouldDisplay = (width) => {
    if (width < 768) {
      return setSwitchMode(true);
    } else {
      return false;
    }
  }

  const getTotaux = () => {
    let level = 0;
    let coffre = 0;
    let levelMax = 0;
    for (let i = 0; i < data[1].length; i++) {
      level = level + data[1][i].championLevel;
      data[1][i].chestGranted && (coffre = coffre + 1);
      levelMax = levelMax + 7;
    }
    setLevelTotal(level);
    setChestTotal(coffre);
    setLevelMax(levelMax);
  };

  if (data && data[0] && data[0].name) {
    window.document.title = data[0].name + " - Masteries";
  }

  if (leLoading) {
    return (
      <div className="flex flex-col justify-between">
        <Header />
        <div className="m-auto mt-20 flex items-center gap-[5px]">
          {" "}
          <strong className="font-boogaloo text-2xl">Chargement</strong>
          <UseAnimations animation={loading} size={56} />
        </div>
      </div>
    );
  }

  return (
    <>
      <Template className="flex ">
        <div
          className={`fixed right-0 top-0 border-l-yellow  flex pt-[123px] flex-col gap-[50px] items-center border-l-[5px] ${!filtresOpen && "translate-x-full"
            } duration-300 h-screen w-screen sm:w-[400px] bg-white z-10`}
        >
          <button
            onClick={() => setFiltresOpen(false)}
            className={`absolute sm:hidden   font-boogaloo text-xl md:text-2xl uppercase text-grey-darker -translate-y-1/2 md:-translate-y-0 left-0 top-[80px] px-8 md:w-[196px] z-10 ${filtresOpen && ""
              } duration-300 py-2.5 md:h-[68px] bg-yellow rounded-r-lg flex items-center justify-center`}
          >
            {filtresOpen ? "Réduire" : "Filtres"}
          </button>
          <FilterItem className="hidden md:flex" title="Mode de visualisation">
            <Switch switchMode={switchMode} setSwitchMode={setSwitchMode} />
          </FilterItem>
          <FilterItem title="Cacher / Afficher maitrises">
            <div className="flex flex-col gap-[30px] w-full items-center">
              <FilterButton
                hide={hide7}
                setHide={() => setHide7(!hide7)}
                level="7"
              />
              <FilterButton
                hide={hide6}
                setHide={() => setHide6(!hide6)}
                level="6"
              />
              <FilterButton
                hide={hide5}
                setHide={() => setHide5(!hide5)}
                level="5"
              />
            </div>
          </FilterItem>
          <FilterItem title="Trier par">
            <select
              onChange={(e) => filterList(e.target.value)}
              className="bg-grey pl-[15px] px-2.5 truncate border-yellow border rounded  w-[300px] h-10"
            >
              <option value="pointsDesc">Points de maitrise décroissant</option>
              <option value="pointsAsc">Points de maitrise croissant</option>
              <option value="chestYes">Coffre obtenu</option>
              <option value="chestNo">Coffre pas obtenu</option>
              <option value="dateDesc">Partie la plus ancienne</option>
              <option value="dateAsc">Partie la plus récente</option>
              <option value="progressDesc">Progression décroissante</option>
              <option value="progressAsc">Progression croissante</option>
            </select>
          </FilterItem>
        </div>
        {data ? (
          <div className="flex flex-col w-full items-center mb-32">
            <button
              onClick={() => setFiltresOpen(!filtresOpen)}
              className={`absolute md:fixed font-boogaloo text-xl md:text-2xl uppercase text-grey-darker -translate-y-1/2 md:-translate-y-0 right-0 top-[210px] md:top-[160px] px-8 md:w-[196px] z-10 ${filtresOpen && "-translate-x-[100vw] sm:-translate-x-[400px]"
                } duration-300 py-2.5 md:h-[68px] bg-yellow rounded-l-lg flex items-center justify-center`}
            >
              {filtresOpen ? "Réduire" : "Filtres"}
            </button>
            <div className="max-w-[1528px]   w-full">
              <div className="m-auto justify-center px-6 lg:px-12 xl:px-[100px] flex  md:flex-row flex-col items-center gap-2.5 md:gap-[25px] mt-[50px] mb-[40px]">
                <img
                  alt="champion"
                  className="w-20 h-20"
                  src={`${data && data[0] && data[0].icon}`}
                />
                <div className="flex flex-col justify-between min-w-[150px]">
                  <div className="relative">
                  <h2 className="font-boogaloo text-[40px]">
                    {data && data[0] && data[0].name}
                  </h2>
                  {((data && data[0] && data[0].name === "Sieur Geoffrey") || (data && data[0] && data[0].name === "RetireD Starløng")) && <img src="/moustache.png" alt="moustache" className="absolute w-[70px] h-[70px] -right-0 translate-x-full top-1/2 -translate-y-1/2"/>}
                  </div>
                  <div className="flex items-center justify-between w-full font-poppins">
                    <p>Niveau {data && data[0] && data[0].level}</p>
                    <p>EUW</p>
                  </div>
                </div>
              </div>
              <div className="flex items-center px-6 lg:px-12 xl:px-[100px] gap-y-10 md:justify-around md:flex-nowrap flex-wrap mb-[30px]">
                <div className="w-1/2 md:w-[200px] items-center flex flex-col ">
                  <p className="font-boogaloo text-2xl">
                    {data && data[1].length}
                  </p>
                  <p className="font-poppins">Champions possédés</p>
                </div>
                <div className="w-1/2 md:w-[200px] items-center flex flex-col">
                  <p className="font-boogaloo text-2xl">
                    {data && data[0].totalMasteries}
                  </p>
                  <p className="font-poppins">Maitrises totale </p>
                </div>
                <div className="w-1/2 md:w-[200px] items-center flex flex-col">
                  <p className="font-boogaloo text-2xl">{levelTotal}</p>
                  <p className="font-poppins">Niveaux (/{levelMax})</p>
                </div>
                <div className="w-1/2 md:w-[200px] items-center flex flex-col">
                  <p className="font-boogaloo text-2xl">{chestTotal}</p>
                  <p className="font-poppins">
                    Coffres (/{data && data[1].length})
                  </p>
                </div>
              </div>
              <p className="font-boogaloo px-6 lg:px-12 xl:px-[100px] text-[40px] text-left mb-10 mt-16">
                {filterName}
              </p>
              {!switchMode ? (
                <div className="w-full px-6 lg:px-12 xl:px-[100px] grid grid-cols-12 gap-8 max-w-[1528px] items-start ">
                  <div className="col-span-6 flex flex-col">
                    {Object.keys(championsByLevel)
                      .sort((a, b) => b - a)
                      .map((level, i) => {
                        const championsForLevel = championsByLevel[level];
                        const championListItems = championsForLevel.map(
                          (champion, index) => (
                            <img
                              onMouseEnter={() => setSelectedChampion(champion)}
                              onMouseLeave={() => setSelectedChampion(null)}
                              src={champion?.championImage}
                              className="w-16 aspect-square ease-in-out hover:scale-[1.20] duration-300"
                              alt={champion.championName}
                              key={index}
                            />
                          )
                        );
                        return (
                          <div
                            className={`w-full mb-20 ${level === "7" && hide7 && "hidden"
                              } ${level === "6" && hide6 && "hidden"} ${level === "5" && hide5 && "hidden"
                              }`}
                            key={level}
                          >
                            <h2
                              onMouseEnter={() => setDisplayLength(i)}
                              onMouseLeave={() => setDisplayLength(null)}
                              className={`text-[40px] text-left mb-5 font-boogaloo ${handleTitleColor(
                                level
                              )}`}
                            >
                              Maitrise {level}{" "}
                              <span
                                className={`text-xl duration-300 opacity-0 ${displayLength !== null &&
                                  displayLength === i &&
                                  "!opacity-100"
                                  }`}
                              >
                                ({championListItems?.length})
                              </span>
                            </h2>
                            <div className="">
                              <ul className="grid grid-cols-6 lg:grid-cols-8 gap-[15px] ">
                                {championListItems}
                              </ul>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div
                    className={`opacity-0 ${handleBoxShadow(
                      selectedChampion?.championLevel
                    )}  translate-x-[20%] flex px-4 lg:px-[30px] py-5 col-span-4 col-start-8  flew-row-reverse justify-center  rounded-[24px] items-center mt-[100px] sticky top-1/3  gap-10  ${selectedChampion
                      ? "opacity-100 !translate-x-0 duration-500 delay-100"
                      : ""
                      } `}
                  >
                    <div className="flex flex-col ">
                      <p className="font-boogaloo text-left text-2xl mb-1 lg:text-[40px] ">
                        {" "}
                        {selectedChampion?.championName}
                      </p>
                      <div
                        className={`flex justify-between lg:text-base text-sm items-center ${selectedChampion?.champion !== 7 && "mb-3 "
                          } gap-5`}
                      >
                        <p>{selectedChampion?.lastPlayTime}</p>
                        <img
                          className={`w-5 ${!selectedChampion?.chestGranted && "notEarned"
                            }`}
                          alt="coffre-acquis"
                          src="https://championmastery.gg/img/chest.png"
                        />
                      </div>
                      {selectedChampion?.championLevel === 6 &&
                        selectedChampion?.tokensEarned === 0 ? (
                        <div className="flex items-center  gap-[5px]">
                          <img
                            className={`w-5 notEarned`}
                            alt="coffre-acquis"
                            src="https://championmastery.gg/img/token.png"
                          />
                          <img
                            className={`w-5 notEarned`}
                            alt="coffre-acquis"
                            src="https://championmastery.gg/img/token.png"
                          />
                          <img
                            className={`w-5 notEarned`}
                            alt="coffre-acquis"
                            src="https://championmastery.gg/img/token.png"
                          />{" "}
                        </div>
                      ) : selectedChampion?.championLevel === 6 &&
                        selectedChampion?.tokensEarned === 1 ? (
                        <div className="flex items-center  gap-[5px]">
                          <img
                            className={`w-5`}
                            alt="coffre-acquis"
                            src="https://championmastery.gg/img/token.png"
                          />
                          <img
                            className={`w-5 notEarned`}
                            alt="coffre-acquis"
                            src="https://championmastery.gg/img/token.png"
                          />
                          <img
                            className={`w-5 notEarned`}
                            alt="coffre-acquis"
                            src="https://championmastery.gg/img/token.png"
                          />{" "}
                        </div>
                      ) : selectedChampion?.championLevel === 6 &&
                        selectedChampion?.tokensEarned === 2 ? (
                        <div className="flex items-center  gap-[5px]">
                          <img
                            className={`w-5`}
                            alt="coffre-acquis"
                            src="https://championmastery.gg/img/token.png"
                          />
                          <img
                            className={`w-5`}
                            alt="coffre-acquis"
                            src="https://championmastery.gg/img/token.png"
                          />
                          <img
                            className={`w-5 notEarned`}
                            alt="coffre-acquis"
                            src="https://championmastery.gg/img/token.png"
                          />{" "}
                        </div>
                      ) : (
                        selectedChampion?.championLevel === 6 &&
                        selectedChampion?.tokensEarned === 3 && (
                          <div className="flex items-center flex-start gap-[5px]">
                            <img
                              className={`w-5`}
                              alt="coffre-acquis"
                              src="https://championmastery.gg/img/token.png"
                            />
                            <img
                              className={`w-5`}
                              alt="coffre-acquis"
                              src="https://championmastery.gg/img/token.png"
                            />
                            <img
                              className={`w-5`}
                              alt="coffre-acquis"
                              src="https://championmastery.gg/img/token.png"
                            />{" "}
                          </div>
                        )
                      )}
                      {selectedChampion?.championLevel === 5 &&
                        selectedChampion?.tokensEarned === 0 ? (
                        <div className="flex items-center  gap-[5px]">
                          <img
                            className={`w-5 notEarned`}
                            alt="coffre-acquis"
                            src="https://championmastery.gg/img/token.png"
                          />
                          <img
                            className={`w-5 notEarned`}
                            alt="coffre-acquis"
                            src="https://championmastery.gg/img/token.png"
                          />{" "}
                        </div>
                      ) : selectedChampion?.championLevel === 5 &&
                        selectedChampion?.tokensEarned === 1 ? (
                        <div className="flex items-center flex-start gap-[5px]">
                          <img
                            className={`w-5`}
                            alt="coffre-acquis"
                            src="https://championmastery.gg/img/token.png"
                          />
                          <img
                            className={`w-5 notEarned`}
                            alt="coffre-acquis"
                            src="https://championmastery.gg/img/token.png"
                          />{" "}
                        </div>
                      ) : (
                        selectedChampion?.championLevel === 5 &&
                        selectedChampion?.tokensEarned === 2 && (
                          <div className="flex items-center flex-start gap-[5px]">
                            <img
                              className={`w-5`}
                              alt="coffre-acquis"
                              src="https://championmastery.gg/img/token.png"
                            />
                            <img
                              className={`w-5`}
                              alt="coffre-acquis"
                              src="https://championmastery.gg/img/token.png"
                            />{" "}
                          </div>
                        )
                      )}
                      {selectedChampion?.championLevel === 4 && (
                        <div className="relative w-full h-[25px] flex  border rounded-full overflow-hidden border-yellow">
                          <p className="absolute w-full lg:text-base text-sm text-center z-10">
                            {selectedChampion?.championPointsUntilNextLevel}
                          </p>
                          <div
                            style={{
                              width: selectedChampion?.tokensEarned + "%",
                            }}
                            className={`absolute bg-yellow h-full`}
                          />
                        </div>
                      )}
                      {selectedChampion?.championLevel === 3 && (
                        <div className="relative w-full h-[25px] flex  border rounded-full overflow-hidden border-yellow">
                          <p className="absolute w-full lg:text-base text-sm text-center z-10">
                            {selectedChampion?.championPointsUntilNextLevel}
                          </p>
                          <div
                            style={{
                              width: selectedChampion?.tokensEarned + "%",
                            }}
                            className={`absolute bg-yellow h-full`}
                          ></div>
                        </div>
                      )}
                      {selectedChampion?.championLevel === 2 && (
                        <div className="relative w-full h-[25px] flex  border rounded-full overflow-hidden border-yellow">
                          <p className="absolute w-full lg:text-base text-sm text-center z-10">
                            {selectedChampion?.championPointsUntilNextLevel}
                          </p>
                          <div
                            style={{
                              width: selectedChampion?.tokensEarned + "%",
                            }}
                            className={`absolute bg-yellow h-full`}
                          ></div>
                        </div>
                      )}
                      {selectedChampion?.championLevel === 1 && (
                        <div className="relative w-full h-[25px] flex  border rounded-full overflow-hidden border-yellow">
                          <p className="absolute w-full lg:text-base text-sm text-center z-10">
                            {selectedChampion?.championPointsUntilNextLevel}
                          </p>
                          <div
                            style={{
                              width: selectedChampion?.tokensEarned + "%",
                            }}
                            className={`absolute bg-yellow h-full`}
                          ></div>
                        </div>
                      )}
                      <p
                        className={`font-bold text-xl lg:text-4xl ${handleTitleColor(
                          selectedChampion?.championLevel
                        )} text-left mt-2.5`}
                      >
                        {" "}
                        {selectedChampion?.championPointsFormated}
                      </p>
                    </div>
                    <img
                      className="w-1/2 h-full"
                      src={`/champions/3d/${selectedChampion?.championName?.toLowerCase()}.png`}
                      alt={selectedChampion?.championName}
                    />
                  </div>
                </div>
              ) : (
                <div className="w-full h-full px-6 lg:px-12 xl:px-[100px] overflow-hidden">
                  <div className="w-full grid grid-cols-8 md:grid-cols-12  gap-[15px] px-2.5">
                    <div className="col-span-10 md:col-span-12 items-center px-[25px] grid grid-cols-8 md:grid-cols-12">
                      <p className="font-boogaloo sm:text-xl md:text-2xl col-span-2 md:col-span-3 text-left">
                        Champion
                      </p>
                      <p className="font-boogaloo sm:text-xl md:text-2xl col-span-1 md:col-span-2 text-left">
                        Niveau
                      </p>
                      <p className="font-boogaloo sm:text-xl md:text-2xl col-span-2 text-center md:text-left">
                        Points
                      </p>
                      <p className="font-boogaloo sm:text-xl md:text-2xl col-span-1 text-left">
                        Coffre
                      </p>
                      <p className="font-boogaloo sm:text-xl md:text-2xl col-span-2 text-right md:text-center">
                        Progression
                      </p>
                      <p className="font-boogaloo md:block hidden sm:text-xl md:text-2xl col-span-2 text-right">
                        Dernière partie
                      </p>
                    </div>
                    <div
                      ref={listRef}
                      className="grid-cols-8     pb-[50px] md:grid-cols-12  col-span-10 md:col-span-12 grid gap-[15px] w-full"
                    >
                      {data &&
                        data[1].map((champion, index) => (
                          <div
                            key={index}
                            ref={(el) => (champion.ref = el)}
                            className={`col-span-10 md:col-span-12 rounded-[1.5rem] items-center translate-x-[10%] opacity-0 px-[25px] duration-500 py-[15px] bg-white shadow-[0_1px_8px_4px_rgb(0,0,0,0.05)] grid grid-cols-8 md:grid-cols-12 ${champion?.championLevel === 7 && hide7 && "hidden"
                              } ${champion?.championLevel === 6 && hide6 && "hidden"
                              } ${champion?.championLevel === 5 && hide5 && "hidden"
                              }`}
                          >
                            <div className="md:flex hidden col-span-3 items-center gap-5">
                              <img
                                src={champion?.championImage}
                                alt={champion?.championName}
                                className="rounded-sm h-16 w-16 bg-grey"
                              />
                              <p>{champion?.championName}</p>
                            </div>
                            <img
                              src={champion?.championImage}
                              alt={champion?.championName}
                              className="rounded-sm col-span-1 md:hidden bg-grey"
                            />
                            <LevelBadge
                              champion={champion}
                              bgColor={handleBgLevelColor}
                              levelColor={handleLevelColor}
                              titleColor={handleTitleColor}
                            />

                            <p className="col-span-2 md:text-base text-sm text-center md:text-left font-bold">
                              {champion?.championPointsFormated}
                            </p>
                            <img
                              className={`w-5 md:w-7 ${!champion?.chestGranted && "notEarned"
                                }`}
                              alt="coffre-acquis"
                              src="https://championmastery.gg/img/chest.png"
                            />
                            {handleProgress(champion)}
                            <p className="col-span-2 md:block hidden text-right">
                              {champion?.lastPlayTime}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <p>
            L'utilisateur n'existe peut-être pas, sinon rééssayez dans quelques
            instants.
          </p>
        )}
      </Template>
    </>
  );
}