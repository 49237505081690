import { useState } from "react";
import { Link } from "react-router-dom";

export default function Header({ setIsOpen, scrollToLeaderboard }) {

  return (
    <header className="h-[119px] w-full bg-transparent flex items-center justify-between max-w-[1528px]">
      <ul className="flex items-center gap-[50px]">
        <li>
          <Link to="/">
            <img alt="logo" src="/logo.png" />
          </Link>
        </li>
        <li className="hidden md:block">
          <Link to="#leaderboard" onClick={() => { scrollToLeaderboard() }} className="font-poppins text-white uppercase cursor-pointer font-medium">Leaderboard</Link>
        </li>
        <li className="hidden md:block">
          <a href="https://www.youtube.com/@biscottefamily4350" className="font-poppins text-white uppercase font-medium">Biscotte family</a>
        </li>
      </ul>
      <p className="text-white uppercase hidden md:block">Biscotte family © {new Date().getFullYear()}</p>
      <svg width="30" height="22" className="md:hidden cursor-pointer" onClick={() => setIsOpen(true)} viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1.5" y1="1.76782" x2="28.5" y2="1.76782" stroke="#F2C879" strokeWidth="3" strokeLinecap="round" />
        <line x1="1.5" y1="11" x2="28.5" y2="11" stroke="#F2C879" strokeWidth="3" strokeLinecap="round" />
        <line x1="1.5" y1="20.2322" x2="28.5" y2="20.2322" stroke="#F2C879" strokeWidth="3" strokeLinecap="round" />
      </svg>

    </header>
  )
}