import "./style.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Header() {

  const [researched, setResearched] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  const handleSubmit = (e) => {
    const newResearch = [...researched, e.target.summoner.value]
    localStorage.setItem('researched', JSON.stringify(newResearch))
    setResearched(newResearch)
  }

  useEffect(() => {
    if (localStorage.getItem('researched')) {
      setResearched(JSON.parse(localStorage.getItem('researched')))
    }
  }
    , [])

  return (
    <>
      <header className="w-full h-[120px] px-6 lg:px-12 xl:px-[100px] bg-grey relative flex justify-center">
        <div className="w-full max-w-[1528px] flex justify-between items-center">
          <div className="absolute h-full w-full rectangle left-0 z-10   bg-yellow">

          </div>
          <div className="absolute h-full w-full rectangle -left-16 hidden lg:block bg-grey top-[30px]">

          </div>
          <ul className="flex items-center z-10 gap-[50px]">
            <li>
              <Link to="/">
                <img alt="logo" src="/logo.png" />
              </Link>
            </li>
            <li className="hidden lg:block">
              <a href="https://www.youtube.com/@biscottefamily4350" className="font-poppins text-grey-darker uppercase font-medium">Biscotte family</a>
            </li>
            <li className="hidden lg:block">
              <Link to="/#leaderboard" className="font-poppins text-grey-darker uppercase cursor-pointer font-medium">Leaderboard</Link>
            </li>
          </ul>

          <form onSubmit={handleSubmit} className="h-1/2 w-1/2  hidden lg:flex items-center justify-end gap-4">
            <input placeholder="Chercher un invocateur" name="summoner" className="h-full w-1/2 bg-white  bg-[#F4F4F4] pl-4 font-medium text-xl border-[3px] text-grey-darker outline-none border-yellow rounded-md" />
            <button type="submit" className="bg-yellow  px-7 h-full rounded-md font-medium text-grey-darker text-xl hover:bg-[#F4D385] duration-200 hover:shadow-light">Rechercher</button>
          </form>
          <svg width="30" height="22" className="lg:hidden cursor-pointer" onClick={() => setIsOpen(true)} viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="1.5" y1="1.76782" x2="28.5" y2="1.76782" stroke="#F2C879" strokeWidth="3" strokeLinecap="round" />
            <line x1="1.5" y1="11" x2="28.5" y2="11" stroke="#F2C879" strokeWidth="3" strokeLinecap="round" />
            <line x1="1.5" y1="20.2322" x2="28.5" y2="20.2322" stroke="#F2C879" strokeWidth="3" strokeLinecap="round" />
          </svg>
        </div>
      </header>
      <div className={`z-50 fixed w-screen h-screen bg-grey-darker flex lg:hidden flex-col justify-between left-0 bottom-0 ${isOpen ? "translate-x-0" : "-translate-x-full"} duration-300`}>
        <div className="flex items-center h-[119px] px-6 justify-between">
          <Link to="/">
            <img alt="logo" src="/logo.png" />
          </Link>
          <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer" onClick={() => setIsOpen(false)} width="40" height="40" viewBox="0 0 24 24"><path className="fill-yellow" d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" /></svg>
        </div>
        <ul className="flex flex-col items-center gap-10">
          <li>
            <Link to="#leaderboard" onClick={() => { "" }} className="font-poppins text-xl text-white uppercase cursor-pointer font-medium">Leaderboard</Link>
          </li>
          <li>
            <a href="https://www.youtube.com/@biscottefamily4350" className="font-poppins text-xl text-white uppercase font-medium">Biscotte family</a>
          </li>
          <li className="w-3/4 sm:w-1/2">
            <form onSubmit={handleSubmit} className=" w-full flex sm:flex-row flex-col items-center sm:h-12 justify-center gap-4">
              <input placeholder="Chercher un invocateur" name="summoner" className="h-14 sm:h-full w-full sm:w-4/5 bg-white  bg-[#F4F4F4] pl-4 font-medium md:text-xl border-[3px] text-grey-darker outline-none border-yellow rounded-md" />
              <button type="submit" className="bg-yellow w-full sm:w-auto  px-3  sm:py-0 py-1 sm:h-full rounded-md font-medium text-grey-darker md:text-xl hover:bg-[#F4D385] duration-200 hover:shadow-light">Rechercher</button>
            </form>
          </li>
        </ul>
        <div className="h-[119px] w-full flex items-center justify-center">
          <p className="text-white uppercase">Biscotte family © {new Date().getFullYear()}</p>
        </div>
      </div>
    </>
  );
}

