import Footer from "./Footer";
import Header from "./Header";
import joinClasses from "../../helpers/joinClasses";

export default function Template({ className = "", children }) {
  return (
    <div className={joinClasses(className, `flex flex-col min-h-screen `)}>
      <Header />
      
      <div className="flex-[1_0_auto] "  >
      {children}
      </div>
      <Footer />
    </div>
  );
}